.ribbon-image-container {
    position: relative;
    display: inline-block;
  }
  
  .ribbon-image {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .ribbon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
   //transform: translate(-50%, -50%);// rotate(-45deg);
  }
  
  .ribbon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    padding: 5px 0;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
    background-color: rgba(239, 83, 80, 0.7);;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  