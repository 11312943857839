#ProductCard {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    width: 12rem; /* Increased width */
    height: 320px; /* Increased height */
}

#ProductCard--badge-placeholder {
    display: flex;
    justify-content: flex-end;

    height: 1.3rem;
    margin-bottom: 5px; /* Increased margin */
}

#ProductCard--image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0;

    height: 135px; /* Increased height */
}

#ProductCard--image-placeholder img {
    object-fit: none;
    width: 145px; /* Increased width */
    //max-height: 128px; /* Removed max-height */
}

#ProductCard--product-name {
    @extend #{'.text-wrap', '.fs-6', '.mb-0'};
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-weight: 450;
    height: 3.2rem;
}

#ProductCard--product-price-placeholder {
    text-align: center;
    font-weight: 550;
    font-size: 1.1rem; /* Increased font size */
}

#ProductCard--product-regular-price {
    @extend #{'.text-muted', '.text-decoration-line-through', '.pe-2'};
    font-weight: 500;
}

#ProductCard--product-price {
}

#ProductCard--product-UOM {
    @extend #{'.ps-1'};
    font-size: 1.1rem; /* Increased font size */
}

#ProductCard--addToCart-placeholder {
    @extend .mt-2;
    display: flex;
    justify-content: center;
}

#ProductCard--addToCart-placeholder button {
    width: 100%;
}