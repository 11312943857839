
#Carousel{
    overflow: hidden;
    max-height: 200px;
    margin: 0.3rem 0;
    height: 200px;
}

#Carousel-img{
   @extend .d-block;
   @extend .w-100;
   object-fit: none;
   height: 200px;
}