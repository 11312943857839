.horizontalLane {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  padding-left: 0.3rem;
}

.horizontalLane::-webkit-scrollbar {
  display: none;
}

.horizontalLane > * {
  flex-shrink: 0;
}
