
#Checkout-title{
    @extend .sticky-top; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    background-color: white;    
}

#Checkout-title--left{
    display: flex;
    
}

#Checkout-title h2{
    font-size: 1.4rem;
    margin: 0;
    margin-left: 0.5rem;
}

#Checkout-title--button {
    @extend #{'.btn', '.btn-success','.btn-sm'};
}

.small-spinner{
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
}