#AddToCart {
    @extend .fs-4;    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#AddToCart-AddButton{
    @extend .btn-success;
    
}

$increase-decrease-button-width: 3rem;

#AddToCart-Decrease{
    @extend .btn-warning;
    width: $increase-decrease-button-width;
}

#AddToCart-Increase{
    @extend .btn-success;
    width: $increase-decrease-button-width;
}

#AddToCart-Quantity{
    padding: 0 1rem;    
    width: 6rem;
    text-align: center;
    display: flex;
    flex-direction: column;
}

#AddToCart-Quantity-UOM{
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
}