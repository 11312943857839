#Header {
  @extend #{'.navbar', '.navbar-expand', '.navbar-dark', '.bg-light'};
  
}

#Header-container{
  @extend .container;
}
#Header-bar{
  display: flex;
  align-items: center;
  padding: 0.3rem;
  width: 100%;
  height: 3rem;
}

#Header-brand {
  margin: 0.3rem 0;
  flex-grow: 1;
  display: flex;
  justify-content: start;
  align-items: center;
}

#Header-brand h1 {
  font-size: medium;
  margin: 0;
  margin-left: 0.5rem;
}
#Header-brand img {
  max-width: 32px;
}

#Header-cart--button{
  @extend #{'.rounded-circle','.btn', '.btn-outline-primary'};
   width: 3rem;
   height: 3rem;
   position: relative;              
}

#Header-cart--items{
  @extend #{'.rounded-circle' ,'.bg-danger','.d-flex','.justify-content-center','.align-items-center'};
  color: #FFF;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%,25%);
}