#SearchPage{
    position: relative;
    min-height: 100vh;
    
  }
  
  #SearchPage .productsGrid{
    padding-bottom: 200px;
  }
  
  #SearchPage #Footer{  
    position: absolute;  
    bottom: 0;
    width: 100vw;  
  }

#SearchPage-header{
    @extend #{'.d-flex','.align-items-center'}
}

#SearchPage-header--back{    
    margin-right: 1rem;
}