$thumb-size: 72px;
$bottom-height: 8.5rem;

#CheckoutCartPage{
    height: 72vh;
    padding: 0 0.5rem;
 }

#CheckoutCartPage-products{
   height: 100%;
   width: 100%;
   overflow-y: scroll;
   padding-bottom: 100px;
}


#CheckoutCartPage-product{
    margin: 1rem 0;
    display: grid;
    grid-template-columns: $thumb-size 1fr;
    column-gap: 0.8rem;

    border-bottom: solid 1px $gray-200;
    padding-bottom: 0.5rem;
}
#CheckoutCartPage-product--img{
    object-fit: contain;

    max-height: $thumb-size;
    width: 100%;

    grid-column: 1;
}

#CheckoutCartPage-product--name{
    @extend .fs-6;
    font-weight: 500;    
}

#CheckoutCartPage-product--amount-placeholder{

}
#CheckoutCartPage-product--amount{
    font-size:  1rem;
    font-weight: 600;    
}

#CheckoutCartPage-product--uom {
    font-size: 0.7rem;
    font-weight: 600;    
}
#CheckoutCartPage-product--actions{
    margin:0.5rem 0;
    display: flex;
    justify-content: space-between;
}

#CheckoutCartPage-product--quantity{
    /* width: 10rem;   */
}

#CheckoutCartPage-cart-empty{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#CheckoutCartPage-cart-empty > *{
    margin-top: 1rem;
}
#CheckoutCartPage-cart-empty--button{
    
}

#CheckoutCartPage-products--bottom{
    @extend #{'.position-sticky','.bottom-0'};        
    border-top: solid 1px $gray-200;    
    height: $bottom-height;
    margin-bottom: rem;
    background-color: white;
}

#CheckoutCartPage-products--bottom-row1{
    padding: 0.7rem 0;
    display: flex;
    justify-content: space-between;  
    gap: 1rem  
}

    
#CheckoutCartPage-products--bottom button{
    //width: 12rem;
}

#CheckoutCartPage-subtotal{
    display: flex;
    flex-direction: column;
}

#CheckoutCartPage-subtotal--label{
    font-size: small;
}

#CheckoutCartPage-subtotal--value{
    font-weight: bold;
    font-size:  x-large;
}

#CheckoutCartPage-empty-cart--button{
    @extend #{'.btn', '.btn-outline-danger', '.btn-sm'};    
}

#CheckoutCartPage-add-products--button{
    @extend #{'.btn', '.btn-success','.btn-sm'};
}

#CheckoutCartPage-products--bottom-row2{
    width: 100%;
}