#Footer {
  background-color: #555;
  height: 9rem;
  //margin-top: 1.5rem;
  padding: 1rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#Footer-platform a {
  color: white;
  font-weight: 600;
}

#Footer-platform a:link {
  text-decoration: none;
}

#Footer-contacto {
  display: flex;
  column-gap: 1.4rem;
  margin: 0.1rem 0;
  font-size: 1.9rem;
}
