#CategoryPage{
  //position: relative;
  //min-height: 100vh;
    max-width: 100vw;
    height: 100%;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#CategoryPage-body{
    padding: 0.5rem;
}
/*
#CategoryPage .productsGrid{
  padding-bottom: 200px;
}

 #CategoryPage #Footer{  
  position: absolute;  
  bottom: 0;
  width: 100vw;  
} */

.categoriesSelector {
  margin: 0.5rem 0;
  display: flex;
  padding: 0.5rem;
}
.categoriesSelector img {
  max-width: 50px;
  max-height: 50px;
}

.categoriesSelector div {
  min-width: 48px;
  /* max-width: 58px;*/
  white-space: initial;
  margin: 0 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.categoriesSelector.selected {
  background-color: var(--bs-secondary);
  color: var(--bs-white);
  border-radius: 10px;
  font-weight: 600;
}

.subCategorySelector {
  margin: 0 0.3rem;
  font-size: 1rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.8rem;
}

.subCategorySelector.selected {
  font-weight: 600;
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-radius: 10px;
}
