#CategoriesGrid-category {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
}

#CategoriesGrid-category img {
    width: 100%;
    max-width: 150px;
    height: auto;
    margin-bottom: 0.5rem;
}

#CategoriesGrid-category span {
    @extend .fs-6;
    font-weight: bold;
}