#root {
    overflow: hidden;
}

section{   
    @extend .mb-5 ;
    @extend .bg-body; 
}

.setoff {
    padding: 1rem;
    
    @extend .shadow;
    @extend .rounded;
}

section h2{
    @extend .lead;
    @extend .fw-semibold;
}

.btn-success {
    @extend #{'.btn', '.btn-lg', '.btn-success'};    
}

.btn-warning {
    @extend #{'.btn', '.btn-lg', '.btn-warning'};    
}

.btn-primary {
    @extend #{'.btn', '.btn-lg', '.btn-primary'};       
}

.btn-dark {
    @extend #{'.btn', '.btn-lg', '.btn-dark'};       
}

.btn-primary-outline{
    @extend #{'.btn', '.btn-lg', '.btn-outline-primary'};       
}


.productsGrid{
    @extend #{'.d-flex','.flex-wrap','.justify-content-between'};
}

.no-records{
    @extend #{'.fst-italic','.text-muted'};
    text-align: center;
    
}

.flex-title{
    display: flex;
    margin: 1rem 0;
}
.flex-title h2{
    font-size: 1.4rem;
    margin: 0;
    margin-left: 0.5rem;
}

.clear-link a:hover, a:active, a:visited, a:link{
    text-decoration: none;
    
}


html, body{    
    height: 100%;    
}

.container{
    padding: 0;
}

#app-div {
    min-height: 100vh;
    height: 100%;    
    display: grid;
    grid-template-rows: 3.5rem 1fr;
}

#LandingPage{    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

#LandingPage-Body{
    padding: 0.5rem;
    max-width: 100vw;
}


.form-check-custom {
    margin-bottom: 1.5rem;
}

.form-check-input-custom {
    transform: scale(1.5);
    margin-right: 1rem;
}

.form-check-label-custom {
    line-height: 1.8;
}

